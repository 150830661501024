































































































































import { Component, Vue } from 'vue-property-decorator';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import emailValidator from '@/utilities/validators/email';
import VButton from '@/components/VButton.vue';
import VDropdown from '@/components/VDropdown.vue';
import * as types from '@/store/main/types';
import { Action } from 'vuex-class';

@Component({ components: { VButton, VDropdown } })
export default class TrialApi extends Vue {

  @Action('contactApi', { namespace: 'main' }) private contactApi!: types.ContactApiAction;

  private trialForm = new ValidatedForm({
    name: new FormField((value: any, dirty: boolean) => value !== '', ''),
    lastName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    companyName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    email: new FormField(emailValidator, ''),
    phone: new FormField((value: any, dirty: boolean) => value !== '', ''),
    supplier: new FormField((value: any, dirty: boolean) => value !== '', ''),
  });
  private submitting = false;
  private showOther = false;
  private supplierOptions = [
    { text: 'Actief Software (APM)', value: 'Actief Software (APM)' },
    { text: 'AFAS (Uitzenden)', value: 'AFAS (Uitzenden)' },
    { text: 'Akyla e-UUR', value: 'Akyla e-UUR' },
    { text: 'EasyFlex (EF2GO)', value: 'EasyFlex (E2GO)' },
    { text: 'Flexservices', value: 'Flexservices' },
    { text: 'Flexsoftware', value: 'Flexsoftware' },
    { text: 'Freepack', value: 'Freepack' },
    { text: 'Mysolution (MSF)', value: 'Mysolution (MSF)' },
    { text: 'Nocore', value: 'Nocore' },
    { text: 'Pivoton', value: 'Pivoton' },
    { text: 'Plan4Flex', value: 'Plan4Flex' },
    { text: 'Tigris', value: 'Tigris' },
    { text: 'Ubplus', value: 'Ubplus' },
    { text: 'Overige', value: 'Overige' },
  ];

  private get trialFormValid() {
    return this.trialForm.fieldValidity();
  }

  private selectSupplier(option: string) {
    if (option === 'Overige') {
      this.trialForm.fields.supplier.value = '';
      this.showOther = true;
    } else {
      this.trialForm.fields.supplier.value = option;
      this.showOther = false;
    }
  }

  private submit() {
    if (this.trialForm.formValid()) {
      this.submitting = true;
      this.contactApi({
        name: this.trialForm.fields.name.value,
        lastName: this.trialForm.fields.lastName.value,
        companyName: this.trialForm.fields.companyName.value,
        email: this.trialForm.fields.email.value,
        phone: this.trialForm.fields.phone.value,
        supplier: this.trialForm.fields.supplier.value,
      })
      .then(() => {
        (this.$refs['trial-api-contact-modal'] as any).show();
      })
      .catch(() => {
        (this.$refs['trial-api-error-modal'] as any).show();
      })
      .finally(() => this.submitting = false);
    }
  }
}


